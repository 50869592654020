import React, { useState, createRef, useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import tw, { css } from 'twin.macro'
import ReactTooltip from 'react-tooltip'
import { v4 as uuid } from 'uuid'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  DeviceMobileOutline,
  FingerPrintOutline,
  GlobeAltOutline,
  AtSymbolOutline,
  UserGroupOutline,
  WifiOutline,
  DesktopComputerOutline,
  CircleOutline,
  CheckCircle,
  ShieldCheck,
  ShieldCheckOutline,
  LightningBolt,
  TrashOutline,
} from '../icons'

const Index = ({ data }) => {
  // TODO: Use rule IDs for as much as possible to speed things up

  const stickyKey = `privacy-checkup-rule-selection`
  const uuidKey = `privacy-checkup-results-id`
  const dataURL = '/.netlify/functions/results'
  const defaultRules = []
  const [progressViz, setProgressViz] = useState(0)
  const [sessionId] = useState(() => {
    const stickyValue =
      typeof window === 'undefined'
        ? null
        : window.localStorage.getItem(uuidKey)
    const id = stickyValue === null ? uuid() : JSON.parse(stickyValue)
    return id
  })
  const [selectedRules, setSelectedRules] = useState(() => {
    const stickyValue =
      typeof window === 'undefined'
        ? null
        : window.localStorage.getItem(stickyKey)

    const rules = stickyValue === null ? defaultRules : JSON.parse(stickyValue)
    return rules
  })

  const focusIndex = useRef(null)
  const emailRef = useRef(null)
  const headerEmailRef = useRef(null)

  const { title } = data.site.siteMetadata
  const { hash } = data.gitCommit
  const { name: tag } = data.gitTag
  const ruleData = data.allPrivacyCheckupCsv.nodes
  const rules = []
  const refs = ruleData.map(() => createRef(null))

  let totalRules = 0
  let categories = {}
  let categoryRules = []
  let category = ruleData[0].Category

  const appMetadata = {
    resultsId: sessionId,
    appVersionId: hash.slice(0, 7),
    appVersion: tag,
  }

  const icons = [
    { category: `Authentication`, icon: <FingerPrintOutline /> },
    { category: `Web Browsing`, icon: <GlobeAltOutline /> },
    { category: `Email`, icon: <AtSymbolOutline /> },
    { category: `Social Media`, icon: <UserGroupOutline /> },
    { category: `Network`, icon: <WifiOutline /> },
    { category: `Mobile Phone`, icon: <DeviceMobileOutline /> },
    { category: `Personal Computer`, icon: <DesktopComputerOutline /> },
  ]

  // TODO: This is triggered on every interaction!
  // uncomment below to see it in action
  // const essentialRules = 0
  // console.log(`essentialRules`, essentialRules)

  ruleData.map((row, rowIndex) => {
    // Incremement the total rule count
    totalRules++
    // Incremement the essential rule count
    // if (row.Essential === 'TRUE') {
    //   essentialRules++
    // }

    if (row.Category !== category) {
      categories[category] = categoryRules.length
      // New category, add previous category rules to array
      rules.push(categoryRules)
      // start with new empty category rules
      categoryRules = []
      // Set new category name
      category = row.Category
    }

    // Cretate new rule
    const rule = {
      id: rowIndex,
      ruleIndex: rowIndex,
      category: row.Category,
      rule: row.Rule,
      comment: row.Comment,
      essential: row.Essential === 'TRUE',
    }

    // Add the rule to the current category
    categoryRules.push(rule)

    if (ruleData.length === rowIndex + 1) {
      // We're on the last row and will exit the `map`, add this rule
      rules.push(categoryRules)
      categories[category] = categoryRules.length
    }

    return true
  })

  async function postData(url = '', data = {}) {
    // Default options are marked with *
    /* eslint no-undef: "off" */
    const response = await fetch(url, {
      body: JSON.stringify(data), // body data type must match "Content-Type" header
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // mode: 'cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    return response.json() // adding .json() parses a JSON response (if that's what's returned) into native JavaScript objects
  }

  const collectResults = (email = null) => {

    const complete = {}

    selectedRules.map((selectedRule) => {
      if (complete[selectedRule.category]) {
        return complete[selectedRule.category]++
      }

      complete[selectedRule.category] = 1
      return true
    })

    let completedItems = {}

    for (const cat in categories) {
      const completedItem = complete[cat] || 0
      const decimalComplete = parseFloat((completedItem / categories[cat]).toPrecision(2))

      completedItems[cat] = decimalComplete
    }

    const data = {
      complete: completedItems,
      ...{
        ...appMetadata,
        completePercent: parseFloat((selectedRules.length / totalRules).toPrecision(2)),
      },
    }

    if (email) {
      data.email = email
    }

    // Write ID to to localstorage so we associate results to a new subscription, if permitted
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(uuidKey, JSON.stringify(sessionId))
    }

    return data
  }

  const handleCompletion = async () => {
    const data = collectResults()

    try {
      await postData(dataURL, data)
    } catch (error) {
      console.log('The checklist results submission failed', error)
    }
  }

  const handleSubmit = async (event, ref) => {
    event.preventDefault()
    // Validate this is an actual email address
    const emailAddress = ref.current.value
    const data = collectResults(emailAddress)

    // Send email and results (if permitted) to server
    try {
      const sendChecklistResults = await postData(dataURL, data)
      if (sendChecklistResults.redirectURL) {
        // Redirect to thank you page
        window.location.href = sendChecklistResults.redirectURL
      }
    } catch (error) {
      console.log('The checklist results submission failed', error)
    }
  }

  const handleReset = () => {
    setSelectedRules([])
    if (typeof window !== 'undefined') {
      window.localStorage.clear()
    }
  }

  const RuleButton = ({
    index,
    id,
    category,
    essential,
    rule,
    comment,
    catIndex,
    catLength,
  }) => {
    useEffect(() => {
      if (focusIndex.current !== null) {
        refs[focusIndex.current].current.focus()
      }

      window.localStorage.setItem(stickyKey, JSON.stringify(selectedRules))
      setProgressViz(selectedRules.length > 0 ? 1 : 0)
      focusIndex.current = null
    })

    const ruleIsSelected = (id) => selectedRules.some((rule) => rule.id === id)

    const handleSelectedChange = () => {
      if (ruleIsSelected(id)) {
        setSelectedRules(selectedRules.filter((rule) => rule.id !== id))
      } else {
        setSelectedRules((previousSelectedRules) => [
          ...previousSelectedRules,
          {
            id,
            category,
            rule,
          },
        ])
      }

      focusIndex.current = index
    }

    return (
      <button
        ref={refs[index]}
        id={id}
        tabIndex={0}
        type="button"
        css={css`
          ${[
            tw`w-full text-left block py-5 md:py-8 transition-colors transition-opacity transition-shadow duration-500 hover:shadow-inner hover:bg-gray-100 hover:bg-opacity-75 hover:cursor-pointer focus:bg-gray-100 focus:bg-opacity-75 focus:shadow-inner focus:outline-none focus:ring-pink-500 focus:ring-inset focus:ring-1 focus:border-pink-500`,
            catIndex !== catLength - 1 &&
              tw`border-b border-gray-100 focus:border-gray-100`,
            catIndex === catLength - 1 && tw`rounded-b-2xl focus:rounded-b-2xl`,
            ruleIsSelected(id) &&
              tw`focus:bg-yellow-100 hover:bg-yellow-100 bg-yellow-100 bg-opacity-50`,
          ]}
        `}
        aria-controls={id}
        onClick={handleSelectedChange}
      >
        <div
          css={css`
            ${[tw`flex items-center px-4 md:px-8`, comment && tw`mb-4`]}
          `}
        >
          <div
            css={css`
              ${tw`text-pink-500`}
            `}
          >
            {ruleIsSelected(id) ? <CheckCircle /> : <CircleOutline />}
          </div>
          <h3
            css={css`
              ${[
                tw`font-mono md:text-xl text-gray-700 ml-4 md:ml-6`,
                /* ruleIsSelected(id) && tw`opacity-50`, */
                essential && tw`font-semibold`,
              ]}
            `}
            title={`${rule} | ${category}`}
          >
            {rule}
          </h3>
          {essential && (
            <span
              role="img"
              aria-label="essential"
              title="The most amount of protection for the least amount of effort"
              css={css`
                ${tw`font-mono text-xl text-yellow-500 ml-auto`}
              `}
            >
              <LightningBolt />
            </span>
          )}
        </div>
        <div>
          {comment && (
            <p
              css={css`
                ${tw`text-sm md:text-base px-6 text-gray-500 md:px-8`}
              `}
            >
              {comment}
            </p>
          )}
        </div>
      </button>
    )
  }

  const Progress = () => {
    return (
      <div
        css={css`
          ${tw`flex items-center justify-between w-full p-6 md:rounded-lg bg-white border border-indigo-100 shadow-lg md:shadow-xl`}
        `}
      >
        <h3
          css={css`
            ${tw`hidden md:block text-lg text-gray-700`}
          `}
        >
          Progress
        </h3>
        <div
          css={css`
            ${tw`w-full bg-indigo-100 mr-8 md:mx-8 rounded-full overflow-hidden`}
          `}
        >
          <div
            css={css`
              ${tw`bg-indigo-500 h-2`}
            `}
            style={{
              width: `${(selectedRules.length / totalRules) * 100}%`,
            }}
          />
        </div>
        <button
          type="button"
          css={css`
            ${tw`text-indigo-300 cursor-pointer hover:text-red-600 transition-colors`}
          `}
          onClick={handleReset}
        >
          <TrashOutline />
        </button>
        <a
          css={css`
            ${[
              tw`ml-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm leading-4 font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500`,
            ]}
          `}
          href="/#completion"
          onClick={(event) => handleCompletion(event)}
        >
          Done
        </a>
      </div>
    )
  }

  return (
    <Layout>
      <SEO title={title} />
      <div
        css={css`
          ${tw``}
        `}
      >
        <div
          id="header"
          css={css`
            ${tw`w-full text-center text-white bg-indigo-800 bg-gradient-to-br from-indigo-800 to-pink-500 px-4 py-12 md:px-6 md:pt-16 md:pb-24`}
          `}
        >
          <div id="headline">
            <span
              css={css`
                ${tw`mx-auto inline-block`}
              `}
            >
              <ShieldCheckOutline size={128} strokeWidth={1} />
            </span>
            <h1
              css={css`
                ${tw`mb-8 md:mb-6 text-4xl md:text-6xl`}
              `}
            >
              {title}
            </h1>
            <h2
              css={css`
                ${tw`font-mono text-xl md:text-2xl mb-8 md:mb-12`}
              `}
            >
              The digital privacy & security assessment tool for parents.
            </h2>
            <p
              css={css`
                ${tw`text-base md:text-lg text-pink-200`}
              `}
            >
              See how well you're doing and learn how to improve.
            </p>
          </div>
        </div>
        <div
          css={css`
            ${tw`w-full bg-gray-100 relative pt-8`}
          `}
        >
          <div
            css={css`
              ${tw`mb-4 p-4 md:px-6 md:w-10/12 xl:w-6/12 mx-auto text-center`}
            `}
          >
            <div
              css={css`
                ${tw`px-4 md:px-12 pt-8 pb-6`}
              `}
            >
              <p
                css={css`
                  ${tw`mb-6`}
                `}
              >
                If you'd like the best privacy tips and news delivered to your
                inbox every week, leave your email address below.
              </p>
              <div
                css={css`
                  ${tw`md:w-10/12 xl:w-8/12 mx-auto`}
                `}
              >
                <form>
                  <div
                    css={css`
                      ${tw`mt-1 flex rounded-md shadow-sm`}
                    `}
                  >
                    <div
                      css={css`
                        ${tw`relative flex items-stretch flex-grow focus-within:z-10`}
                      `}
                    >
                      <input
                        ref={headerEmailRef}
                        type="text"
                        name="email"
                        id="email-signup-header"
                        tabIndex={0}
                        css={css`
                          ${tw`focus:ring-pink-500 focus:border-pink-500 block w-full rounded-none rounded-l-md sm:text-sm border-pink-600`}
                        `}
                        placeholder="Your email address"
                      />
                    </div>
                    <button
                      type="submit"
                      css={css`
                        ${tw`-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-pink-600 text-sm font-medium rounded-r-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-1 focus:ring-pink-500 focus:border-pink-500`}
                      `}
                      onClick={(event) => handleSubmit(event, headerEmailRef)}
                    >
                      <span>Sign up!</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div>
            <div
              css={css`
                ${tw`md:w-10/12 xl:w-6/12 pb-12 mx-auto`}
              `}
            >
              {/* #Actions */}
              <div
                css={css`
                  ${tw`sticky top-0 md:pt-6 bg-gray-100 z-10`}
                `}
              >
                <div
                  id="actions"
                  css={css`
                    ${tw`bg-white mb-4 p-4 md:px-6 w-full md:rounded-md shadow-md`}
                  `}
                >
                  <ul
                    css={css`
                      ${tw`flex justify-between`}
                    `}
                  >
                    {/* #Action tabs */}
                    {icons.map((category, index) => (
                      <li
                        key={`menu-tab-${index}`}
                        css={css`
                          ${tw`inline-block`}
                        `}
                      >
                        <a
                          tabIndex={0}
                          href={`/#${category.category
                            .replace(/\s+/g, '-')
                            .toLowerCase()}`}
                          title={category.category}
                          data-tip="categories"
                          data-for={`category-${index}`}
                        >
                          <span
                            css={css`
                              ${tw`p-1 rounded-full bg-gray-100 hover:bg-indigo-200 text-indigo-500 hover:text-white hover:bg-pink-500 transition-colors duration-300 mr-2 block`}
                            `}
                          >
                            {category.icon}
                          </span>
                          <ReactTooltip
                            id={`category-${index}`}
                            effect="solid"
                            uuid={`cat-tooltip-${index}`}
                          >
                            <span>{category.category}</span>
                          </ReactTooltip>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* #Legend */}
              <div
                id="legend"
                css={css`
                  ${tw`text-center`}
                `}
              >
                <div
                  id="legend"
                  css={css`
                    ${tw`inline-flex items-center text-left mx-auto mt-4 bg-gray-200 px-4 py-2 rounded-full text-xxs sm:text-xs md:text-sm text-gray-500 shadow-inner border-b border-white`}
                  `}
                >
                  <span
                    role="img"
                    aria-label="essential"
                    css={css`
                      ${tw`text-yellow-500 mr-2`}
                    `}
                  >
                    <LightningBolt />
                  </span>{' '}
                  Most amount of protection for the least amount of effort
                </div>
              </div>

              {/* #Rules */}
              <div
                id="rules"
                css={css`
                  ${tw`px-4 md:px-6`}
                `}
              >
                {rules.map((category, categoryIndex) => {
                  const catLength = category.length
                  const currentCategory = category[0].category

                  return (
                    <div
                      key={`category-${categoryIndex}`}
                      id={`${currentCategory
                        .replace(/\s+/g, '-')
                        .toLowerCase()}`}
                      css={css`
                        ${tw`mb-12 bg-white rounded-2xl pt-16 -mt-16 md:pt-20 md:-mt-20 bg-clip-content`}
                      `}
                    >
                      <div key={`category-${categoryIndex}`}>
                        {/* Category title */}
                        <div
                          css={css`
                            ${tw`sticky top-16 md:top-20 pt-8 bg-gray-100`}
                          `}
                        >
                          <div
                            css={css`
                              ${tw`flex flex-wrap md:flex-nowrap items-start md:items-center justify-between p-5 md:p-8 rounded-t-2xl bg-indigo-500 text-white`}
                            `}
                          >
                            <div
                              css={css`
                                ${tw`flex items-center mb-3 md:mb-0`}
                              `}
                            >
                              <span
                                css={css`
                                  ${tw`inline-block mr-4`}
                                `}
                                role="img"
                                aria-label="web-browsing"
                              >
                                {icons[categoryIndex].icon}
                              </span>
                              <h3
                                css={css`
                                  ${tw`text-lg md:text-2xl tracking-wider uppercase whitespace-pre`}
                                `}
                              >
                                {currentCategory}{' '}
                              </h3>
                            </div>
                            <div
                              css={css`
                                ${tw`w-full bg-indigo-300 md:mx-8 rounded-full overflow-hidden order-2 order-last md:order-none`}
                              `}
                            >
                              <div
                                css={css`
                                  ${tw`bg-indigo-700 h-1`}
                                `}
                                style={{
                                  width: `${
                                    (selectedRules.filter(
                                      (selectedRule) =>
                                        selectedRule.category ===
                                        currentCategory,
                                    ).length /
                                      category.length) *
                                    100
                                  }%`,
                                }}
                              />
                            </div>
                            <span
                              css={css`
                                ${[
                                  tw`text-2xl text-indigo-100`,
                                  selectedRules.filter(
                                    (selectedRule) =>
                                      selectedRule.category === currentCategory,
                                  ).length === category.length &&
                                    tw`text-white`,
                                ]}
                              `}
                            >
                              {selectedRules.filter(
                                (selectedRule) =>
                                  selectedRule.category === currentCategory,
                              ).length === category.length ? (
                                <ShieldCheck />
                              ) : (
                                category.length
                              )}
                            </span>
                          </div>
                        </div>
                        <div
                          css={css`
                            ${tw`rounded-2xl`}
                          `}
                        >
                          {/* Rules */}
                          {category.map((categoryRules, catIndex) => {
                            const {
                              id,
                              ruleIndex,
                              rule,
                              comment,
                              essential,
                              category,
                            } = categoryRules
                            return (
                              <RuleButton
                                key={`rule-${id}`}
                                id={id}
                                index={ruleIndex}
                                category={category}
                                essential={essential}
                                rule={rule}
                                comment={comment}
                                catIndex={catIndex}
                                catLength={catLength}
                              />
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>

              {/* #Progress */}
              <div
                css={css`
                  ${[
                    tw`z-30 sticky transition-all md:bottom-4 bottom-0 transform translate-y-12 opacity-0 duration-500`,
                    progressViz > 0 && tw`-translate-y-0 opacity-100`,
                  ]}
                `}
              >
                <Progress />
              </div>
            </div>
          </div>
          <div
            css={css`
              ${tw`w-full mx-auto border-t border-gray-200`}
            `}
          >
            <div
              css={css`
                ${tw`md:w-10/12 xl:w-6/12 mx-auto pb-16 flex flex-col text-center`}
              `}
            >
              <div
                id="completion"
                css={css`
                  ${tw`px-8 pt-12 md:pt-20 mb-2`}
                `}
              >
                <h2
                  css={css`
                    ${tw`mb-12 text-3xl`}
                  `}
                >
                  Congrats! How did you do?
                </h2>
                <p
                  css={css`
                    ${tw`mb-8 text-xl`}
                  `}
                >
                  If you'd like privacy & security tips and news delivered to
                  your inbox every week, leave your email address below.
                </p>
              </div>

              {/* #Sign up */}
              <div
                css={css`
                  ${tw`mx-4 md:mx-8 md:mx-auto md:w-8/12 p-6 md:p-12 pt-4 md:pt-8 bg-white shadow-md rounded-md mb-24`}
                `}
              >
                <div
                  css={css`
                    ${tw`w-full mx-auto text-center`}
                  `}
                >
                  <span
                    css={css`
                      ${tw`mx-auto inline-block text-indigo-500 mb-1`}
                    `}
                  >
                    <ShieldCheck size={72} />
                  </span>
                  <p
                    css={css`
                      ${tw`mb-4`}
                    `}
                  >
                    Tell me where to send your Privacy Self-Defense newsletter:
                  </p>
                  <form>
                    <div>
                      <div
                        css={css`
                          ${tw`flex flex-col rounded-md shadow-sm`}
                        `}
                      >
                        <input
                          ref={emailRef}
                          type="text"
                          name="email"
                          id="email-sign-up-footer"
                          tabIndex={0}
                          css={css`
                            ${tw`focus:ring-pink-600 focus:border-pink-600 block w-full border-pink-500 bg-gray-50 focus:bg-white mb-2`}
                          `}
                          placeholder="Your email address"
                        />

                        <button
                          type="submit"
                          tabIndex={0}
                          css={css`
                            ${tw`items-center space-x-2 px-4 py-2 border border-pink-500 font-medium rounded-md text-white text-center bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-1 focus:ring-pink-500 focus:border-pink-500`}/* leading-4  */
                          `}
                          onClick={(event) => handleSubmit(event, emailRef)}
                        >
                          <span>Sign up!</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              {/* #Meta */}
              <div
                css={css`
                  ${tw`mx-4 md:mx-8 md:mx-auto md:w-8/12`}
                `}
              >
                <p
                  css={css`
                    ${tw`text-center text-gray-400 text-sm`}
                  `}
                >
                  {tag}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    gitCommit(latest: { eq: true }) {
      hash
    }
    gitTag(latest: { eq: true }) {
      name
    }
    allPrivacyCheckupCsv {
      nodes {
        Category
        Rule
        Comment
        Essential
      }
    }
  }
`
